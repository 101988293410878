import { sroStyle } from '@amedia/brick-tokens';

import type { BrickInputData } from './types';
import {
  inputStyle,
  labelStyle,
  helpTextStyle,
  errorTextStyle,
  optionalTextStyle,
} from './styles.js';

// Create UUID
export const generateUUID = () => {
  if (typeof crypto === 'object') {
    if (typeof crypto.randomUUID === 'function') {
      // https://developer.mozilla.org/en-US/docs/Web/API/Crypto/randomUUID
      return crypto.randomUUID();
    }
    if (
      typeof crypto.getRandomValues === 'function' &&
      typeof Uint8Array === 'function'
    ) {
      // https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
      const callback = (c) => {
        const num = Number(c);
        return (
          num ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (num / 4)))
        ).toString(16);
      };
      return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, callback);
    }
  }
  let timestamp = new Date().getTime();
  let perforNow =
    (typeof performance !== 'undefined' &&
      performance.now &&
      performance.now() * 1000) ||
    0;
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let random = Math.random() * 16;
    if (timestamp > 0) {
      random = (timestamp + random) % 16 | 0;
      timestamp = Math.floor(timestamp / 16);
    } else {
      random = (perforNow + random) % 16 | 0;
      perforNow = Math.floor(perforNow / 16);
    }
    return (c === 'x' ? random : (random & 0x3) | 0x8).toString(16);
  });
};

export const brickInputTemplate = (
  data: BrickInputData,
  isRendered?: boolean
) => {
  const {
    type = 'text',
    errortext = 'Feltet må fylles ut korrekt.',
    helptext,
    accept,
    alt,
    autocomplete,
    autofocus,
    checked,
    disabled,
    id,
    labelHidden,
    labelText,
    max,
    maxlength,
    min,
    minlength,
    multiple,
    name,
    optionaltext,
    pattern,
    placeholder,
    readonly,
    required,
    size,
    spellcheck,
    src,
    step,
    value,
    list,
  } = data;

  let labelProps = '';
  let inputProps = '';

  // For the label
  if (labelHidden) {
    labelProps += 'data-label-hidden ';
  }

  if (labelText) {
    labelProps += `data-label-text="${labelText}" `;
  }

  // For the input
  if (accept) {
    inputProps += `accept="${accept}" `;
  }

  if (alt) {
    inputProps += `alt="${alt}" `;
  }

  if (autocomplete) {
    inputProps += `autocomplete="${autocomplete}" `;
  }

  if (autofocus) {
    inputProps += 'autofocus ';
  }

  if (checked) {
    inputProps += 'checked ';
  }

  if (disabled) {
    inputProps += 'disabled ';
  }

  if (errortext) {
    inputProps += `data-errortext="${errortext}" `;
  }

  if (helptext) {
    inputProps += `data-helptext="${helptext}" `;
  }

  let uuid = '';
  if (id) {
    uuid = id;
    labelProps += `for="${uuid}" `;
    inputProps += `id="${uuid}" `;
  } else if (labelText && !id) {
    // Add a unique id if none is provided
    uuid = generateUUID();
    inputProps += `id="${uuid}" `;
    labelProps += `for="${uuid}" `;
  }

  if (max) {
    inputProps += `max="${max}" `;
  }

  if (maxlength) {
    inputProps += `maxlength="${maxlength}" `;
  }

  if (min) {
    inputProps += `min="${min}" `;
  }

  if (minlength) {
    inputProps += `minlength="${minlength}" `;
  }

  if (multiple) {
    inputProps += 'multiple ';
  }

  if (name) {
    inputProps += `name="${name}" `;
  }

  if (optionaltext) {
    inputProps += `data-optionaltext="${optionaltext}" `;
  }

  if (pattern) {
    inputProps += `pattern="${pattern}" `;
  } else if (type === 'email') {
    const emailRegex = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$';
    inputProps += `pattern="${emailRegex}"`;
  }

  if (placeholder) {
    inputProps += `placeholder="${placeholder}" `;
  }

  if (readonly) {
    inputProps += 'readonly ';
  }

  if (required) {
    inputProps += 'required ';
  }

  if (size) {
    inputProps += `size="${size}" `;
  }

  if (spellcheck) {
    inputProps += 'spellcheck ';
  }

  if (src) {
    inputProps += `src="${src}" `;
  }

  if (step) {
    inputProps += `step="${step}" `;
  }

  if (type) {
    inputProps += `type="${type}" `;
  }

  if (value) {
    inputProps += `value="${value}" `;
  }

  if (list) {
    inputProps += `list="${list}" `;
  }

  const isRadioOrCheckbox = type === 'radio' || type === 'checkbox';
  const hideLabel = labelHidden ? `${sroStyle} ` : '';

  const asterisk = `<span class="asterisk" aria-hidden="true">*</span>`;
  const optional = `${
    optionaltext
      ? `<span class="optional ${optionalTextStyle}">(${optionaltext})</span>`
      : ''
  }`;
  // Only set a label if the type is not submit, reset, button, hidden or image
  // If the input is a radio or checkbox, we do not add the asterix to the label
  let label = '';
  if (
    labelText &&
    type !== 'submit' &&
    type !== 'reset' &&
    type !== 'button' &&
    type !== 'hidden' &&
    type !== 'image'
  ) {
    label = `<label class="${labelStyle} ${hideLabel}" ${labelProps}>${labelText} ${
      required && !isRadioOrCheckbox ? asterisk : optional
    }</label>`;
  }

  const markup = `
    ${label}
  
    ${
      helptext
        ? `<p id="help-${uuid}" class="help ${helpTextStyle}">${helptext}</p>`
        : ''
    }
    <p id="error-${uuid}" class="error ${errorTextStyle}"></p>
  
    <input class="${inputStyle}" aria-describedby="help-${uuid} error-${uuid}" ${inputProps}>${
      required && (hideLabel || !labelText) ? asterisk : ''
    }
  `;

  if (isRendered) {
    return `<brick-input-v2 is-rendered>${markup}</brick-input-v2>`;
  }

  return markup;
};

export const renderBrickInput = (dataset: BrickInputData) => {
  return brickInputTemplate(dataset, true);
};
export { getCssText } from '@amedia/brick-tokens';
