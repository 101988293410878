// Validating input against pattern attribute
export function validateInput(
  inputElement: HTMLInputElement,
  errorElement: HTMLElement,
  ariaLiveRegion: HTMLElement
) {
  const patternAttribute = inputElement.getAttribute('pattern');
  if (!patternAttribute) return;

  const pattern = new RegExp(patternAttribute);
  const isRequired = inputElement.hasAttribute('required');
  const isEmpty = !inputElement.value.trim();

  if (
    (!pattern.test(inputElement.value) && (isRequired || !isEmpty)) ||
    (isEmpty && isRequired)
  ) {
    const parentElement = inputElement.parentElement;

    if (
      parentElement &&
      parentElement.tagName.toLowerCase() === 'brick-input-v2'
    ) {
      inputElement.setAttribute('aria-invalid', 'true');
      errorElement.textContent = parentElement.dataset.errortext || '';

      // Update the aria-live region with the error message
      ariaLiveRegion.textContent = errorElement.textContent;
    } else {
      inputElement.removeAttribute('aria-invalid');
      errorElement.textContent = '';

      // Clear the aria-live region
      ariaLiveRegion.textContent = '';
    }
  } else {
    if (inputElement.hasAttribute('aria-invalid')) {
      inputElement.removeAttribute('aria-invalid');
    }
    errorElement.textContent = '';

    // Clear the aria-live region
    ariaLiveRegion.textContent = '';
  }
}
