import {
  BrickElement,
  defineCustomElement,
  type EventListenerObject,
} from '@amedia/brick-template';
import { sroStyle } from '@amedia/brick-tokens';
import { brickInputTemplate } from './template';
import type { BrickInputData } from './types';
import { validateInput } from './utils.js';

@defineCustomElement({
  selector: 'brick-input-v2',
})
export class BrickInput extends BrickElement {
  data: BrickInputData;
  private _onchange!: ((this: GlobalEventHandlers, ev: Event) => void) | null;

  constructor(brickInputData: BrickInputData) {
    super();
    this.data = brickInputData;
  }

  async connectedCallback() {
    this.setData();
    super.connectedCallback();

    // Add asterisk to legend if input is required and legend exists. This only applies to radio and checkbox inputs.
    if (
      (this.data.required && this.data.type === 'radio') ||
      (this.data.required && this.data.type === 'checkbox')
    ) {
      const legend = this.parentElement?.querySelector('legend');

      if (legend && !legend.querySelector('.asterisk')) {
        const asterisk = document.createElement('span');
        asterisk.textContent = ' *';
        asterisk.classList.add('asterisk');
        legend.appendChild(asterisk);
      }
    }

    // Add aria-live region for error messages
    function createAriaLiveRegion() {
      const ariaLiveRegion = document.createElement('div');
      ariaLiveRegion.setAttribute('aria-live', 'polite');
      ariaLiveRegion.setAttribute('aria-atomic', 'true');
      ariaLiveRegion.classList.add(`${sroStyle}`);
      return ariaLiveRegion;
    }

    const ariaLiveRegion = createAriaLiveRegion();
    this.appendChild(ariaLiveRegion);

    this.data.accept ? this.setAttribute('data-accept', this.data.accept) : '';
    this.data.alt ? this.setAttribute('data-alt', this.data.alt) : '';
    this.data.autocomplete
      ? this.setAttribute('data-autocomplete', this.data.autocomplete)
      : '';
    this.data.autofocus
      ? this.setAttribute('data-autofocus', this.data.autofocus.toString())
      : '';
    this.data.checked
      ? this.setAttribute('data-checked', this.data.checked.toString())
      : '';
    this.data.disabled
      ? this.setAttribute('data-disabled', this.data.disabled.toString())
      : '';
    this.data.errortext
      ? this.setAttribute('data-errortext', this.data.errortext)
      : '';
    this.data.helptext
      ? this.setAttribute('data-helptext', this.data.helptext)
      : '';
    this.data.id ? this.setAttribute('data-id', this.data.id) : '';
    this.data.labelHidden
      ? this.setAttribute('data-label-hidden', this.data.labelHidden.toString())
      : '';
    this.data.labelText
      ? this.setAttribute('data-label-text', this.data.labelText)
      : '';
    this.data.max ? this.setAttribute('data-max', this.data.max) : '';
    this.data.maxlength
      ? this.setAttribute('data-maxlength', this.data.maxlength)
      : '';
    this.data.min ? this.setAttribute('data-min', this.data.min) : '';
    this.data.minlength
      ? this.setAttribute('data-minlength', this.data.minlength)
      : '';
    this.data.multiple
      ? this.setAttribute('data-multiple', this.data.multiple.toString())
      : '';
    this.data.name ? this.setAttribute('data-name', this.data.name) : '';
    this.data.optionaltext
      ? this.setAttribute('data-optionaltext', this.data.optionaltext)
      : '';
    this.data.pattern
      ? this.setAttribute('data-pattern', this.data.pattern)
      : '';
    this.data.placeholder
      ? this.setAttribute('data-placeholder', this.data.placeholder)
      : '';
    this.data.readonly
      ? this.setAttribute('data-readonly', this.data.readonly.toString())
      : '';
    this.data.required
      ? this.setAttribute('data-required', this.data.required.toString())
      : '';
    this.data.list ? this.setAttribute('data-list', this.data.list) : '';
    this.data.size ? this.setAttribute('data-size', this.data.size) : '';
    this.data.src ? this.setAttribute('data-src', this.data.src) : '';
    this.data.spellcheck
      ? this.setAttribute('data-spellcheck', this.data.spellcheck.toString())
      : '';
    this.data.step ? this.setAttribute('data-step', this.data.step) : '';
    this.data.type
      ? this.setAttribute('data-type', this.data.type || 'text')
      : '';
    this.data.value ? this.setAttribute('data-value', this.data.value) : '';

    const inputElements =
      this.querySelectorAll<HTMLInputElement>('input[pattern]');

    inputElements.forEach((inputElement) => {
      const errorElement =
        inputElement.previousElementSibling as HTMLElement | null;

      if (errorElement && errorElement.classList.contains('error')) {
        inputElement.addEventListener('blur', () => {
          validateInput(inputElement, errorElement, ariaLiveRegion);
        });
      }
    });
  }

  // Add event listeners to radio buttons and checkboxes
  get eventListeners(): EventListenerObject[] {
    const checkedChange = [
      {
        selector: 'input[type="radio"]',
        action: 'change',
        listener: this.onCheckedChange.bind(this),
      },
      {
        selector: 'input[type="checkbox"]',
        action: 'change',
        listener: this.onCheckedChange.bind(this),
      },
    ];

    if (this.onChange) {
      return [
        ...checkedChange,
        {
          selector: 'input',
          action: 'change',
          listener: this.onChange.bind(this),
        },
      ];
    }
    return checkedChange;
  }
  onCheckedChange(e) {
    const el = e.currentTarget;
    if (el?.checked) {
      el.setAttribute('checked', 'true');
    } else {
      el.removeAttribute('checked');
    }
  }

  set onChange(callback) {
    this._onchange = callback;
  }

  get onChange() {
    return this._onchange;
  }

  setData() {
    this.data = {
      type: this.getAttribute('data-type') || 'text',
      labelText: this.getAttribute('data-label-text') ?? undefined,
      errortext:
        this.getAttribute('data-errortext') || 'Feltet må fylles ut korrekt.',
      helptext: this.getAttribute('data-helptext') ?? undefined,
      id: this.getAttribute('data-id') ?? undefined,
      labelHidden: this.getAttribute('data-label-hidden') === 'true',
      name: this.getAttribute('data-name') ?? undefined,
      value: this.getAttribute('data-value') ?? undefined,
      maxlength: this.getAttribute('data-maxlength') ?? undefined,
      minlength: this.getAttribute('data-minlength') ?? undefined,
      size: this.getAttribute('data-size') ?? undefined,
      placeholder: this.getAttribute('data-placeholder') ?? undefined,
      min: this.getAttribute('data-min') ?? undefined,
      max: this.getAttribute('data-max') ?? undefined,
      step: this.getAttribute('data-step') ?? undefined,
      required: this.getAttribute('data-required') === 'true',
      optionaltext: this.getAttribute('data-optionaltext') ?? undefined,
      pattern: this.getAttribute('data-pattern') ?? undefined,
      accept: this.getAttribute('data-accept') ?? undefined,
      multiple: this.getAttribute('data-multiple') === 'true',
      src: this.getAttribute('data-src') ?? undefined,
      alt: this.getAttribute('data-alt') ?? undefined,
      autocomplete: this.getAttribute('data-autocomplete') ?? undefined,
      autofocus: this.getAttribute('data-autofocus') === 'true',
      spellcheck: this.getAttribute('data-spellcheck') === 'true',
      readonly: this.getAttribute('data-readonly') === 'true',
      checked: this.getAttribute('data-checked') === 'true',
      disabled: this.getAttribute('data-disabled') === 'true',
      list: this.getAttribute('data-list') || '',
    };
    return this.data;
  }

  static get mirroredProps() {
    return [
      'data-type',
      'data-label-text',
      'data-errortext',
      'data-helptext',
      'data-id',
      'data-label-hidden',
      'data-name',
      'data-value',
      'data-maxlength',
      'data-minlength',
      'data-size',
      'data-placeholder',
      'data-min',
      'data-max',
      'data-step',
      'data-required',
      'data-optionaltext',
      'data-pattern',
      'data-accept',
      'data-multiple',
      'data-src',
      'data-alt',
      'data-autocomplete',
      'data-autofocus',
      'data-spellcheck',
      'data-readonly',
      'data-checked',
      'data-disabled',
      'data-list',
    ];
  }

  /*The HTML getter is used by brick-template to clone content from a <template> element. This is more performant than using innerHTML because it avoids additional HTML parse costs.*/
  get HTML() {
    return brickInputTemplate(this.data);
  }
}
